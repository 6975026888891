import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeaderContact from "../components/PageHeaderContact";

const Contact = () => {
    const { t } = useTranslation();

    return (
        <>
        <PageHeaderContact></PageHeaderContact>
            {/* Start */}
            <section className="relative md:py-24 py-16 bg-gray-100 dark:bg-slate-800" id="contact">
                <div className="container">

                    <h6 className="text-orange-600 text-gray-900 font-medium uppercase mb-8 text-center text-xl">Contact us</h6>

                    <div className="flex pb-8 ">


                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7504.264924822671!2d29.097532221742377!3d37.73951391835629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c73ec1f393ac0f%3A0xec3306035056127b!2zUGFtdWtrYWxlIMOcbml2ZXJzaXRlc2kgS8SxbsSxa2zEsSBLYW1ww7xzw7w!5e0!3m2!1str!2str!4v1693921737809!5m2!1str!2str"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                            width={1000}
                            height={600}
                        ></iframe>

                        <div className="lg:col-span-4 text-xl">
                            <div className="lg:ms-8">

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-map-marker block rounded text-2xl dark:text-gray-900 mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="dark:text-gray-900 mb-2 font-medium">Location</h5>
                                        <p className="text-slate-400 mb-2">Kınıklı Mah., Hüseyin Yılmaz Caddesi, No: 67, Pamukkale Teknokent C Blok 302/13, Pamukkale/Denizli</p>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-phone block rounded text-2xl dark:text-gray-900 mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="dark:text-gray-900 mb-2 font-medium">Phone</h5>
                                        <a href="tel:+152534-468-854" className="text-slate-400">+90 536 2520524</a>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-envelope block rounded text-2xl dark:text-gray-900 mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="dark:text-gray-900 mb-2 font-medium">Email</h5>
                                        <a href="mailto:contact@example.com" className="text-slate-400">admin@datanom.io</a>
                                    </div>
                                </div>



                            </div>
                        </div>

                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-6">
                        <div className="lg:col-span-8">
                            <div className="bg-gray-100 p-6">
                                <form>
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <input name="name" id="name" type="text" className="form-input border-2 border-gray-300 rounded-lg p-2" placeholder="Name :" />
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <input name="email" id="email" type="email" className="form-input border-2 border-gray-300 rounded-lg p-2" placeholder="Email :" />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <input name="subject" id="subject" className="form-input border-2 border-gray-300 rounded-lg p-2" placeholder="Subject :" />
                                        </div>

                                        <div className="mb-5">
                                            <textarea name="comments" id="comments" className="form-input border-2 border-gray-300 rounded-lg p-2 w-full" placeholder="Message :"></textarea>
                                        </div>
                                    </div>
                                    <button type="submit" id="submit" name="send" className="p-2 btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md h-11 justify-center flex items-center">Send Message</button>
                                </form>
                            </div>
                        </div>


                    </div>

                </div>
            </section>
        </>
    );
};

export default Contact;
