import { Link } from "react-router-dom";
import EcommerceImage from "../assets/images/applications/e-commerce.png";
import {CaretRightFilled} from "@ant-design/icons"
const AppCardEcommerce = () =>  {

    const title = "E-COMMERCE"
    const description = "Recommender systems aim to provide personalized recommendations to users, and data mining techniques help achieve this by analyzing user behavior, preferences, and item characteristics."

  return (

      <div className="text-3xl w-[90%] border-2 border-gray-200 rounded-3xl shadow-2xl h-full bg-blue-50 opacity-80">
        <a href="/solutions/ecommerce">
        <div className="pb-2 font-bold text-gray-900 items-center m-1 opacity-90 flex">
        <img src={EcommerceImage} className='w-16 h-16 p-3' />
        {title}
        </div>
        <div className="border-2 border-gray-100"></div>
        <div className="text-2xl p-3 m-1 opacity-90 text-justify">
          {description}
          <div className="text-[#0000ff] pt-8">
            <Link to="/solutions/ecommerce" className="flex">
            <CaretRightFilled/>Read More...
            </Link>
          </div>
        </div>        
        </a>

      </div>
      
  );
}
export default AppCardEcommerce;
