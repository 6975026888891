import React from "react";
import { Link as Link2 } from "react-router-dom";
import BackgroudImage from "../assets/images/bg/3.jpg";

/**
 * Index Component
 */
export default function SecurityBanner() {
  return (
    <>
      <div>
        <section
          style={{ backgroundImage: `url(${BackgroudImage})` }}
          className="py-16 lg:py-16 w-full table relative bg-center bg-cover pl-5"
          id="home"
        >
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="container relative">
            <div className="grid grid-cols-1 mt-12 flex justify-between">
              <div className="">
                    <h4 className="text-white lg:text-4xl text-3xl font-medium mb-7 flex">
                      <i>Providing brilliant solutions in <b className="text-[#8080ff]">security</b> {" "}</i>
                      <div className="ml-3 border"></div> 
                      <div className="ml-3 text-orange-400 text-2xl">
                        <p>Crime Investigation</p>
                        <p>Fraud Detection</p>
                        <p>Intrusion Detection</p>
                        <p>Lie Detection</p>
                      </div>
                    </h4>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
