import { Link } from "react-router-dom";
import HealthcareImage from "../assets/images/applications/healthcare.png";
import {CaretRightFilled} from "@ant-design/icons"
const AppCardHealthcare = () =>  {

  const title = "HEALTHCARE"
  const description = "The category or class of an observation (e.g., disease diagnosis, fault detection) can be predicted, unusual patterns or outliers that deviate from normal behavior (e.g., fraud detection, defect identification) can be identified, similar data points or observations based on similarities (e.g., patient stratification, fault type identification) can be grouped, and a continuous numerical value (e.g., predicting a patient's blood pressure) can be predicted."

  return (
    
      <div className="text-3xl w-[90%] border-2 border-gray-200 rounded-3xl shadow-2xl h-full bg-blue-50 opacity-80">
        <a href="/solutions/healthcare">
        <div className="pb-2 font-bold text-gray-900 items-center m-1 opacity-90 flex">
        <img src={HealthcareImage} className='w-16 h-16 p-3' />
        {title}
        </div>
        <div className="border-2 border-gray-100"></div>
        <div className="text-2xl p-3 m-1 opacity-90 text-justify">
          {description}
          <div className="text-[#0000ff] pt-8">
            <Link to="/solutions/healthcare" className="flex">
            <CaretRightFilled/>Read More...
            </Link>
          </div>
        </div>        
        </a>
      </div>

  );
}
export default AppCardHealthcare;
