import React from 'react';
import { Link } from "react-router-dom";
import DatanomImage from "../assets/images/datanom.png";
import {LinkedinOutlined} from "@ant-design/icons"
import {XOutlined} from "@ant-design/icons"


export default function PageFooter() {

    return (
        <>
            {/*  Start Footer  */}
            <footer className="p-1 bg-gray-900 dark:bg-gray-900">

                <div>
                    {/* <div className="w-full border-2 border-gray-200"></div> */}
                    <div className="items-center flex justify-between w-full">

                        {/* <div><img src={DatanomImage} className='w-24 h-6 pt-1' /></div> */}
                        <div><b className="text-white font-orbitron font-normal">datanom</b></div>

                        <div className='text-white'> datanom.io © {(new Date().getFullYear())}{" "}</div>

                        <div>
                            <ul className="items-center flex">

                                <li className='pl-4'>
                                    <Link to="https://linkedin.com/">
                                        <button class="w-8 h-8 rounded-lg bg-white">
                                        <LinkedinOutlined/>
                                        </button>
                                    </Link>
                                </li>

{/* 
                                <li className='pl-4'>
                                    <Link to="https://www.instagram.com/">
                                        <button class="w-8 h-8 rounded-lg bg-white">

                                        </button>
                                    </Link>
                                </li>


                                <li className='pl-4'>
                                    <Link to="https://www.twitter.com/">
                                        <button class="w-8 h-8 rounded-lg bg-white">
                                        <XOutlined/>
                                        </button>
                                    </Link>
                                </li> */}

                            </ul>
                        </div>

                    </div>
                </div>
            </footer>
            {/* <!-- End Footer --> */}


        </>
    );
}
