import BackgroudImage from "../assets/images/bg/choose-us-bg3.jpg";
import AppCard from "./AppCard"
import SecurityBanner from "./SecurityBanner";
import PageHeaderSolutions from "../components/PageHeaderSolutions";



const SecurityApps = () =>  {

  const CrimeInvestigation = {"title":"Crime Investigation",
                "description":"After the data collected from equipment or systems by sensors, it is preprocessed and then predictive models are developed to predict probable failures in the prediction horizon.",
                "link":"CrimeInvestigation"}

  const FraudDetection = {"title":"Fraud Detection",
                "description":"For the Fraud Detection, Association Rule Mining approaches are used to find relationships between variables, Clustering Algorithms are used to group similar incidents together, Decision Tree Approaches are used to identify key variables leading to the problem and Regression Analysis is used to understand the impact of different factors on the problem.",
                "link":"FraudDetection"}

  const IntrusionDetection = {"title":"Intrusion Detection",
                "description":"Intrusion Detection uses event logs or data related to the process and then performs process discovery to create a visual representation of the process flow, and generates process models, such as process maps, Petri nets, or flowcharts. Moreover, it analyzes process performance metrics, such as cycle time, waiting time, and resource utilization to help identifying bottlenecks and areas for improvement. Finally, it investigates the root causes of process issues, deviations, or bottlenecks by exploring the event data, especially anomalies or repeated patterns.",
                "link":"IntrusionDetection"}

const LieDetection = {"title":"Lie Detection",
              "description":"Alarm data can be analyzed by various Data Mining techniques such as Frequent Item-set Mining and Frequent Sequence Mining to discover “significant” patterns in the data and “interesting” association rules among them, thereby giving insights to the domain experts in making strategical decisions.",
              "link":"LieDetection"}

  return (

    <div className="flex-col" style={{ backgroundImage: `url(${BackgroudImage})` }}>

      <div> <SecurityBanner></SecurityBanner> </div>


      <div className="flex p-8 gap-8">
            <AppCard title={CrimeInvestigation.title} description={CrimeInvestigation.description} link={CrimeInvestigation.link}></AppCard>
            <AppCard title={FraudDetection.title} description={FraudDetection.description} link={FraudDetection.link}></AppCard>
            
      </div>
      <div className="flex p-8 gap-8">
            <AppCard title={IntrusionDetection.title} description={IntrusionDetection.description} link={IntrusionDetection.link}></AppCard>
            <AppCard title={LieDetection.title} description={LieDetection.description} link={LieDetection.link}></AppCard>

      </div>

    </div>
    
  );
}
export default SecurityApps;
