import clientsImage from "../assets/images/clients.png";
import finishedprojectImage from "../assets/images/finished-project.png";

import { Statistic } from "antd";
import CountUp from "react-countup";

const formatter = (value) => (
  <CountUp end={value} separator="" />
);

const HomePageBanner3 = ({ title, description, link }) => {

  return (

    <div  className="bg-gray-50 flex justify-between">

      <div className="flex items-center m-8">
        <div><img src={clientsImage} className='w-24 h-24 pt-1 m-8' /></div>
        <div className="flex-col">
          <div className="text-5xl text-orange-700 text-center">
          <Statistic value={1790} formatter={formatter} valueStyle={{ color: "#c65502", fontSize: "40px" }} />
            </div>
          <div className="text-3xl">Happy clients</div>
        </div>
      </div>

      <div className="flex items-center m-8">
        <div><img src={finishedprojectImage} className='w-24 h-24 pt-1 m-8' /></div>
        <div className="flex-col">
          <div className="text-5xl text-orange-700 text-center">
          <Statistic value={12} formatter={formatter} valueStyle={{ color: "#c65502", fontSize: "40px" }} />
            </div>
          <div className="text-3xl">Finished Projects</div>
        </div>
      </div>

      <div className="flex items-center m-8">
        <div><img src={clientsImage} className='w-24 h-24 pt-1 m-8' /></div>
        <div className="flex-col">
          <div className="text-5xl text-orange-700 text-center">
          <Statistic value={8} formatter={formatter} valueStyle={{ color: "#c65502", fontSize: "40px" }} />
          </div>
          <div className="text-3xl">Skilled Experts</div>
        </div>
      </div>

      <div className="flex items-center m-8">
        <div><img src={finishedprojectImage} className='w-24 h-24 pt-1 m-8' /></div>
        <div className="flex-col">
          <div className="text-5xl text-orange-700 text-center">
            <Statistic value={245} formatter={formatter} valueStyle={{ color: "#c65502", fontSize: "40px" }} />
          </div>
          <div className="text-3xl">Media Posts</div>
        </div>
      </div>



    </div>

  );
}
export default HomePageBanner3;
