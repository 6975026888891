
import { useTranslation } from 'react-i18next';
import React, { useState } from "react";
import PageHeaderAbout from "../components/PageHeaderAbout";
import BackgroudImage from "../assets/images/bg/3.jpg";

const About = () => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <PageHeaderAbout></PageHeaderAbout>
      <div>
        <section
          style={{ backgroundImage: `url(${BackgroudImage})` }}
          className="py-96 lg:py-96 w-full table relative bg-center bg-cover pl-5"
          id="home"
        >
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="container relative">

            <p className="text-gray-300 max-w-2xl text-2xl leading-10">
              Pamukkale Üniversitesi yerleşkesinde bulunan Pamukkale Teknokent'te 2022 yılında Prof. Dr. Serdar İplikçi tarafından kurulan
              datanom Veri Madenciliği ve İş Zekası alanlarında faaliyet gösteren öncü kuruluşlardan biridir.
            </p>
            <p className="ml-5 text-gray-400 max-w-2xl text-2xl leading-10">
              En güncel Veri Madenciliği algoritmaları ile geliştirilmiş olan <i>"datamine+"</i>,
              datanom'un ilk ürünü olup verilerdeki bilgileri açığa çıkararak akılcı ve veriye-dayalı ticari stratejiler geliştirilmesini sağlar.
            </p>

          </div>
        </section>
      </div>
    </>
  );
};

export default About;
