import { Link } from "react-router-dom";
import SecurityImage from "../assets/images/applications/security.png";
import {CaretRightFilled} from "@ant-design/icons"
const AppCardSecurity = () =>  {

  const title = "SECURITY"
  const description = "By using Data Mining techniques, unusual patterns that deviate from expected behavior can be detected, whether a given transaction or activity is fraudulent or not can be predicted and similar transactions or entities can be grouped to detect patterns of fraud."

  return (
    
      <div className="text-3xl w-[90%] border-2 border-gray-200 rounded-3xl shadow-2xl h-full bg-blue-50 opacity-80">
        <a href="/solutions/security">
        <div className="pb-2 font-bold text-gray-900 items-center m-1 opacity-90 flex">
        <img src={SecurityImage} className='w-16 h-16 p-3' />
        {title}
        </div>
        <div className="border-2 border-gray-100"></div>
        <div className="text-2xl p-3 m-1 opacity-90 text-justify">
          {description}
          <div className="text-[#000fff] pt-8">
            <Link to="/solutions/security" className="flex">
            <CaretRightFilled/>Read More...
            </Link>
          </div>
        </div>
        </a>
      </div>

  );
}
export default AppCardSecurity;
