import BackgroudImage from "../assets/images/bg/choose-us-bg3.jpg";
import AppCard from "./AppCard"
import EcommerceBanner from "./EcommerceBanner";
import PageHeaderSolutions from "../components/PageHeaderSolutions";



const EcommerceApps = () =>  {

  const RecommenderSystems = {"title":"Recommender Systems",
                "description":"Recommender systems aim to provide personalized recommendations to users, and data mining techniques help achieve this by analyzing user behavior, preferences, and item characteristics.",
                "link":"RecommenderSystems"}

  const MarketBasketAnalysis = {"title":"Market Basket Analysis",
                "description":"Analyze purchase history to identify items frequently bought together. Use association rule mining to suggest product bundles and improve cross-selling.",
                "link":"MarketBasketAnalysis"}

  const StoreOptimization = {"title":"Store Optimization",
                "description":"Store optimization aims to improve various aspects of retail performance, including customer experience, inventory management, layout design, and overall operational efficiency.",
                "link":"StoreOptimization"}
  
  const MarketingAutomation = {"title":"Marketing Automation",
                "description":"Marketing automation involves using technology to automate repetitive marketing tasks and to deliver personalized and targeted content to potential and existing customers.",
                "link":"MarketingAutomation"}

const LoyaltyProgramDevelopment = {"title":"Loyalty Program Development",
              "description":"Clustering algorithms are used to segment your customers based on behavior, demographics, and purchase history. This helps tailor campaigns to specific customer groups. Customer reviews or feedback are analyzed by using natural language processing (NLP) to understand customer sentiments about products. This can help improve the targeting of your campaigns by highlighting features that customers love or need improvement.",
              "link":"LoyaltyProgramDevelopment"}

const CampaignOptimisation = {"title":"Campaign Optimisation",
              "description":"Data mining contributes significantly to campaign optimization by extracting valuable insights from large datasets, allowing marketers to refine and enhance their marketing campaigns for better performance.",
              "link":"CampaignOptimisation"}              

  return (

    <div className="flex-col" style={{ backgroundImage: `url(${BackgroudImage})` }}>
      <div> <EcommerceBanner></EcommerceBanner> </div>


      <div className="flex p-8 gap-8">
            <AppCard title={RecommenderSystems.title} description={RecommenderSystems.description} link={RecommenderSystems.link}></AppCard>
            <AppCard title={MarketBasketAnalysis.title} description={MarketBasketAnalysis.description} link={MarketBasketAnalysis.link}></AppCard>
            <AppCard title={StoreOptimization.title} description={StoreOptimization.description} link={StoreOptimization.link}></AppCard>
      </div>
      <div className="flex p-8 gap-8">
            <AppCard title={MarketingAutomation.title} description={MarketingAutomation.description} link={MarketingAutomation.link}></AppCard>
            <AppCard title={LoyaltyProgramDevelopment.title} description={LoyaltyProgramDevelopment.description} link={LoyaltyProgramDevelopment.link}></AppCard>
            <AppCard title={CampaignOptimisation.title} description={CampaignOptimisation.description} link={CampaignOptimisation.link}></AppCard>
      </div>

    </div>
    
  );
}
export default EcommerceApps;
