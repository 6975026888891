import React from 'react';
import { useTranslation } from 'react-i18next';
import BackgroudImage from "../assets/images/bg/choose-us-bg3.jpg";
import PageHeaderSolutions from "../components/PageHeaderSolutions";

const ProcessMining = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="mt-24 w-full h-full relative bg-center bg-cover pl-5 border-2 border-blue-900 rounded-lg"
        style={{ backgroundImage: `url(${BackgroudImage})` }}
        id="home"
      >
         
        <div className='items-center text-center'>
              <h3 className="mt-2 text-blue-900 text-4xl font-semibold mb-2">Process Mining</h3>
        </div>

        


      </section>
    </>
  );
};

export default ProcessMining;
