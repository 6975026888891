import React from "react";
import BackgroudImage from "../assets/images/bg/hero-bg1.png";
import { useTranslation } from 'react-i18next';

/**
 * Index Component
 */
export default function HomePageBanner1() {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <section
          style={{ backgroundImage: `url(${BackgroudImage})` }}
          className="py-16 lg:py-16 w-full table relative bg-center bg-cover pl-5"
          id="home"
        >
 
 
            <div className="grid grid-cols-2 mt-12 items-center">

                        <div>
                                <div className="text-slate-900 mb-10 max-w-2xl text-6xl">
                                {t("homepage_banner1_text1")}...
                                </div>

                                <div className="text-3xl max-w-2xl ml-10">
                                    <b className="text-[#0000ff] font-orbitron font-normal">datanom</b>
                                    <i>, {t("homepage_banner1_text2")}
                                    </i>
                                </div>
                        </div>

                    <div className="grid grid-cols-2 ml-36">

                        <a href="/">
                            <div class="grid grid-cols-2">
                                <i className="text-3xl">{t("homepage_banner1_text3")}</i>
                                <span>
                                <span className="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-sky-400 opacity-65"></span>
                                <span className="relative inline-flex rounded-full h-10 w-10 bg-sky-500"></span> 

                                </span>
                            </div>
                        </a>
                    </div>

            </div>
 
        </section>
      </div>
    </>
  );
}
