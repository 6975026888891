import BackgroudImage from "../assets/images/bg/choose-us-bg3.jpg";
import AppCard from "./AppCard"
import IndustryBanner from "./IndustryBanner";
import PageHeaderSolutions from "../components/PageHeaderSolutions";



const IndustryApps = () =>  {

  const PredictiveMaintenance = {"title":"Predictive Maintenance",
                "description":"After the data collected from equipment or systems by sensors, it is preprocessed and then predictive models are developed to predict probable failures in the prediction horizon.",
                "link":"PredictiveMaintenance"}

  const RootCauseAnalysis = {"title":"Root Cause Analysis",
                "description":"For the Root Cause Analysis, Association Rule Mining approaches are used to find relationships between variables, Clustering Algorithms are used to group similar incidents together, Decision Tree Approaches are used to identify key variables leading to the problem and Regression Analysis is used to understand the impact of different factors on the problem.",
                "link":"RootCauseAnalysis"}

  const ProcessMining = {"title":"Process Mining",
                "description":"Process Mining uses event logs or data related to the process and then performs process discovery to create a visual representation of the process flow, and generates process models, such as process maps, Petri nets, or flowcharts. Moreover, it analyzes process performance metrics, such as cycle time, waiting time, and resource utilization to help identifying bottlenecks and areas for improvement. Finally, it investigates the root causes of process issues, deviations, or bottlenecks by exploring the event data, especially anomalies or repeated patterns.",
                "link":"ProcessMining"}
  
const AlarmAnalysis = {"title":"Alarm Analysis",
              "description":"Alarm data can be analyzed by various Data Mining techniques such as Frequent Item-set Mining and Frequent Sequence Mining to discover “significant” patterns in the data and “interesting” association rules among them, thereby giving insights to the domain experts in making strategical decisions.",
              "link":"AlarmAnalysis"}

const TimeSeriesForecasting = {"title":"Time Series Forecasting",
              "description":"Time Series Forecasting is a powerful technique for predicting future values based on previously observed time data. In the context of your e-commerce business, you can use it to forecast sales, customer demand, or campaign performance over time.",
              "link":"TimeSeriesForecasting"}              

  return (

    <div className="flex-col" style={{ backgroundImage: `url(${BackgroudImage})` }}>

      <div> <IndustryBanner></IndustryBanner> </div>


      <div className="flex p-8 gap-8">
            <AppCard title={PredictiveMaintenance.title} description={PredictiveMaintenance.description} link={PredictiveMaintenance.link}></AppCard>
            <AppCard title={RootCauseAnalysis.title} description={RootCauseAnalysis.description} link={RootCauseAnalysis.link}></AppCard>
            <AppCard title={ProcessMining.title} description={ProcessMining.description} link={ProcessMining.link}></AppCard>
      </div>
      <div className="flex p-8 gap-8">
            <AppCard title={AlarmAnalysis.title} description={AlarmAnalysis.description} link={AlarmAnalysis.link}></AppCard>
            <AppCard title={TimeSeriesForecasting.title} description={TimeSeriesForecasting.description} link={TimeSeriesForecasting.link}></AppCard>
      </div>

    </div>
    
  );
}
export default IndustryApps;
