import { Link } from "react-router-dom";
import IndustryImage from "../assets/images/applications/industry.png";
import {CaretRightFilled} from "@ant-design/icons"
const AppCardIndustry = () =>  {

const title = "INDUSTRY"
const description = "Alarm data can be analyzed by various Data Mining techniques such as Frequent Itemset Mining and Frequent Sequence Mining to discover “significant” patterns in the data and “interesting” association rules among them, thereby giving insights to the domain experts in making strategical decisions."

  return (
      <div className="text-3xl w-[90%] border-2 border-gray-200 rounded-3xl shadow-2xl h-full bg-blue-50 opacity-80">
        <a href="/solutions/industry">
        <div className="pb-2 font-bold text-gray-900 items-center m-1 opacity-90 flex">
        <img src={IndustryImage} className='w-16 h-16 p-3' />
        {title}
        </div>
        <div className="border-2 border-gray-100"></div>
        <div className="text-2xl p-3 m-1 opacity-90 text-justify">
          {description}
          <div className="text-[#000fff] pt-8">
            <Link to="/solutions/industry" className="flex">
            <CaretRightFilled/>Read More...
            </Link>
          </div>
        </div>
        </a>
      </div>
      
  );
}
export default AppCardIndustry;
