import BackgroudImage from "../assets/images/bg/choose-us-bg3.jpg";
import AppCard from "./AppCard"
import HealthcareBanner from "./HealthcareBanner";
import PageHeaderSolutions from "../components/PageHeaderSolutions";



const HealthcareApps = () =>  {

  const Diagnostics = {"title":"Diagnostics",
                      "description":"The category or class of an observation (e.g., disease diagnosis, fault detection) can be predicted, unusual patterns or outliers that deviate from normal behavior (e.g., fraud detection, defect identification) can be identified, similar data points or observations based on similarities (e.g., patient stratification, fault type identification) can be grouped, and a continuous numerical value (e.g., predicting a patient's blood pressure) can be predicted.",
                      "link":"Diagnostics"}

  const PublicHealth = {"title":"Public Health",
                "description":"For the Public Health, Association Rule Mining approaches are used to find relationships between variables, Clustering Algorithms are used to group similar incidents together, Decision Tree Approaches are used to identify key variables leading to the problem and Regression Analysis is used to understand the impact of different factors on the problem.",
                "link":"PublicHealth"}

  const GeneExpression = {"title":"Gene Expression",
                "description":"Gene Expression uses event logs or data related to the process and then performs process discovery to create a visual representation of the process flow, and generates process models, such as process maps, Petri nets, or flowcharts. Moreover, it analyzes process performance metrics, such as cycle time, waiting time, and resource utilization to help identifying bottlenecks and areas for improvement. Finally, it investigates the root causes of process issues, deviations, or bottlenecks by exploring the event data, especially anomalies or repeated patterns.",
                "link":"GeneExpression"}

  return (

    <div className="flex-col" style={{ backgroundImage: `url(${BackgroudImage})` }}>
      
      <div> <HealthcareBanner></HealthcareBanner> </div>


      <div className="flex p-8 gap-8">
            <AppCard title={Diagnostics.title} description={Diagnostics.description} link={Diagnostics.link}></AppCard>
            <AppCard title={PublicHealth.title} description={PublicHealth.description} link={PublicHealth.link}></AppCard>
            
      </div>
      <div className="flex p-8 gap-8">
            <AppCard title={GeneExpression.title} description={GeneExpression.description} link={GeneExpression.link}></AppCard>

      </div>

    </div>
    
  );
}
export default HealthcareApps;
