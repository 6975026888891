import BackgroudImage from "../assets/images/bg/choose-us-bg3.jpg";
import AppCard from "../appareas/AppCard"
import AppCardIndustry from "../appareas/AppCardIndustry"
import AppCardHealthcare from "../appareas/AppCardHealthcare"
import AppCardEcommerce from "../appareas/AppCardEcommerce"
import AppCardSecurity from "../appareas/AppCardSecurity"



const ApplicationAreas = ({title,description,link}) =>  {

  const industry = {"title":"INDUSTRY",
                    "description":"Alarm data can be analyzed by various Data Mining techniques such as Frequent Item-set Mining and Frequent Sequence Mining to discover “significant” patterns in the data and “interesting” association rules among them, thereby giving insights to the domain experts in making strategical decisions.",
                    "link":"industry",
                    "icon":"industry.png"}




  return (

    <div className="flex-col" style={{ backgroundImage: `url(${BackgroudImage})` }}>

      <div className="flex p-8 gap-8">
            <AppCardEcommerce/>         
            <AppCardIndustry/>
      </div>

      <div className="flex p-8 gap-8">
            <AppCardSecurity/>
            <AppCardHealthcare/>
      </div>

    </div>
    
  );
}
export default ApplicationAreas;
